.mobilenav-container {
	position: fixed;
	display: none;
	flex-direction: column;
	align-items: center;
	z-index: 999;
	width: 100%;
}

.mobilenav-container .navbar {
	width: 100%;
	height: 8vh;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	z-index: 999;
	background-color: #fff;
	box-shadow: 0 4px 12px 0 rgb(0 0 0 / 5%);
	/* background-color: red; */
}

.mobilenav-container .logo {
	width: 4rem;
	height: 4rem;
}

.mobilenav-container .logo img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.mobilenav-container .hamburger-menu {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 2rem;
	transform: rotate(0) translateX(0) translateY(0);
	transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
	user-select: none !important;
}

.mobilenav-container .hamburger-menu .bar {
	width: 2rem;
	height: 3px;
	background-color: black;
	transform: rotate(0) translateX(0) translateY(0);
	transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
	user-select: none !important;
}

.mobilenav-container .hamburger-menu .bar:nth-child(2) {
	margin: 6px 0;
}

.mobilenav-container .hamburger-menu.joint .bar:nth-child(1) {
	transform: translateY(9px);
	transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.mobilenav-container .hamburger-menu.joint .bar:nth-child(3) {
	transform: translateY(-9px);
	transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.mobilenav-container .hamburger-menu.cross .bar:nth-child(1) {
	transform: rotate(90deg) translateX(0.6rem);
	transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.mobilenav-container .hamburger-menu.cross .bar:nth-child(3) {
	transform: translateY(-9px);
	transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.mobilenav-container .hamburger-menu.cross {
	transform: rotate(225deg);
	transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
	user-select: none !important;
}

.mobilenav-container .menues-list {
	position: absolute;
	top: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	height: 100vh;
	width: 100%;
	transform: translateY(-100vh);
	transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
	z-index: 1;
	background-color: #fff;
	padding-bottom: 15vh;
}

.mobilenav-container .menues-list .upper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.mobilenav-container .menues-list .lower {
	/* background-color: #f00; */
	height: 20vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.mobilenav-container .menues-list.active {
	transform: translateY(8vh);
	transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.mobilenav-container .menues-list p {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 1.5rem;
	font-weight: 500;
	color: #334052;
	font-family: "SairaExtraCondensed-Black";
	cursor: pointer;
}

.mobilenav-container .menues-list .menu-item {
	height: 10vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mobilenav-container .menues-list .line {
	height: 5vh;
	width: 80%;
}

.mobilenav-container .menues-list .line img {
	width: 100%;
	height: 100%;
}

.mobilenav-container .menues-list .logo-bottom {
	width: 7rem;
}

.mobilenav-container .menues-list .logo-bottom img {
	width: 100%;
	object-fit: contain;
}

.mobilenav-container .menues-list .media-link {
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1rem;
}

.mobilenav-container .menues-list .media-link a {
	margin: 0 1.5rem;
	cursor: pointer;
	color: #c4c4c4;
	transition: all 0.5s ease-in-out;
	text-decoration: none;
}

@media only screen and (max-width: 768px) {
	.mobilenav-container {
		display: flex;
	}
}
