.card-main-container {
	width: 280px;
	height: 360px;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-radius: 0px;
	overflow: hidden;
	margin: 1rem;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	transition: all 0.35s ease-in-out;
}

.card-main-container:hover {
	border: 1px solid #dab90d;
	border-radius: 7px;
	transition: all 0.35s ease-in-out;
}

.card-main-container .card-body {
	width: 100%;
	height: 300px;
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: #fff;
}

.card-main-container .card-body .card-image-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.card-main-container .card-body .card-image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transform: scale(1);
	transition: all 0.5s ease-in-out;
}

.card-main-container:hover .card-body .card-image-container img {
	transform: scale(1.2);
	transition: all 1s ease-in-out;
}

.card-main-container .card-body .card-icon {
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
}

.card-main-container .card-footer {
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	padding: 1rem;
	background-color: #f9f9fb;
}

.card-main-container .card-footer p {
	font-size: 1rem;
	color: #dab90d;
}

@media only screen and (max-width: 950px) {
	.card-main-container {
		height: 300px;
		width: 200px;
		border: 1px solid #dab90d;
	}
	.card-main-container .card-body {
		height: 250px;
	}

	.card-main-container .card-footer {
		height: 50px;
	}
}

@media only screen and (max-width: 426px) {
	.card-main-container {
		height: 270px;
		width: 180px;
		margin: 0.5rem;
		border: 1px solid #dab90d;
	}
	.card-main-container .card-body {
		height: 230px;
	}

	.card-main-container .card-footer {
		height: 40px;
	}

	.card-main-container .card-footer p {
		font-size: 10px;
	}
}
