.contact-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-container .contact-banner {
	width: 100%;
	height: 400px;
	background-color: #303030;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.contact-container .contact-banner p {
	color: #fbd784;
	letter-spacing: 2px;
	font-size: 14px;
	margin-bottom: 1rem;
}

.contact-container .contact-banner h1 {
	color: #fbd784;
	font-size: 5rem;
	font-family: "apple-garamond";
	font-weight: lighter;
	letter-spacing: 5px;
}

.contact-container .book-appointment {
	width: 50%;
	margin: 1rem 0;
}

.contact-container .book-appointment button {
	width: 100%;
	height: 50px;
	align-self: center;
	background-color: #303030;
	outline: none;
	border: none;
	color: #fff;
	font-family: "apple-garamond";
	font-size: 1rem;
	letter-spacing: 1px;
	cursor: pointer;
}

.contact-container .contact-address {
	width: 100%;
	height: 620px;
	background-color: #f2f2f2;
	display: flex;
	align-items: center;
	justify-content: center;
}

.contact-container .contact-address .address-left {
	width: 60%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.contact-container .contact-address .address-left h1 {
	font-size: 40px;
	font-weight: lighter;
	font-family: "apple-garamond";
	letter-spacing: 2px;
}

.contact-container .contact-address .address-left .address-section {
	width: 60%;
	display: flex;
	justify-content: space-between;
	margin-top: 5rem;
	/* padding: 0 5rem; */
}

.contact-container .contact-address .address-left .address-section div {
	display: flex;
	flex-direction: column;
	/* background-color: red; */
}

.contact-container .contact-address .address-left .address-section div p {
	font-size: 14px;
	letter-spacing: 2px;
	margin-bottom: 1rem;
	color: rgba(0, 0, 0, 0.35);
}

.contact-container .contact-address .address-left .address-section div span {
	font-size: 14px;
	line-height: 28px;
	letter-spacing: 1px;
	color: rgba(0, 0, 0, 0.65);
}

.contact-container .contact-address .address-right {
	width: 40%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: black;
}

.contact-container .contact-address .address-right img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	/* filter: grayscale(100%); */
}

.contact-container .contact-form {
	width: 75%;
	/* background-color: red; */
	margin: 5rem 0;
	display: flex;
	flex-direction: column;
}

.contact-container .contact-form .contact-form-head {
	width: 100%;
}

.contact-container .contact-form .contact-form-head h1 {
	font-family: "apple-garamond";
	font-weight: lighter;
	font-size: 40px;
	letter-spacing: 2px;
}

.contact-container .contact-form .contact-form-body {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 2rem;
}

.contact-container .contact-form .contact-form-body .form-group {
	width: 30%;
	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;
}

.contact-container .contact-form .contact-form-body .form-group2 {
	width: 65%;
	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;
}

.contact-container .contact-form .contact-form-body .form-group label {
	font-size: 13px;
	color: rgba(0, 0, 0, 0.35);
	margin-bottom: 0.5rem;
	letter-spacing: 2px;
}

.contact-container .contact-form .contact-form-body .form-group input {
	border: none;
	outline: none;
	padding: 0.75rem;
	background-color: #f2f2f2;
}

.contact-container .contact-form .contact-form-body .form-group2 label {
	font-size: 13px;
	color: rgba(0, 0, 0, 0.35);
	margin-bottom: 0.5rem;
	letter-spacing: 2px;
}

.contact-container .contact-form .contact-form-body .form-group2 textarea {
	border: none;
	outline: none;
	padding: 0.75rem;
	background-color: #f2f2f2;
}

.contact-container .contact-form .contact-form-body .form-group3 {
	width: 30%;
	display: flex;
	align-items: flex-end;
	margin-bottom: 1.5rem;
}

.contact-container .contact-form .contact-form-body .form-group3 button {
	width: 100%;
	padding: 0.75rem;
	background-color: #303030;
	border: none;
	outline: none;
	color: #fff;
	letter-spacing: 2px;
	cursor: pointer;
}

@media only screen and (max-width: 1250px) {
	.contact-container .contact-banner {
		height: 350px;
	}

	.contact-container .contact-address {
		height: 500px;
	}

	.contact-container .contact-address .address-left .address-section {
		display: flex;
		flex-direction: column;
	}
}

@media only screen and (max-width: 900px) {
	.contact-container .contact-banner {
		height: 350px;
	}

	.contact-container .contact-address {
		height: 500px;
		/* background-color: red; */
	}

	.contact-container .contact-address .address-left {
		padding: 1rem 0;
	}

	.contact-container .contact-address .address-left .address-section {
		display: flex;
		flex-direction: column;
		height: 400px;
		/* background-color: blue; */
	}
}

@media only screen and (max-width: 769px) {
	.contact-container .contact-banner {
		height: 300px;
	}

	.contact-container .contact-banner h1 {
		font-size: 30px;
	}

	/* .contact-container .contact-address {
    height: 450px;
  } */
	.contact-container .contact-address {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 600px;
	}

	.contact-container .contact-address .address-left {
		width: 100%;
		height: 50%;
	}

	.contact-container .contact-address .address-right {
		width: 100%;
		height: 50%;
	}

	.contact-container .contact-address .address-left h1 {
		font-size: 28px;
	}

	.contact-container .contact-address .address-left .address-section {
		display: flex;
		flex-direction: row;
		margin-top: 2rem;
	}
}

@media only screen and (max-width: 768px) {
	.contact-container .contact-banner {
		margin-top: 8vh;
	}
	.contact-container .contact-address {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 900px;
	}
	.contact-container .contact-address .address-left .address-section {
		padding: 5px;
		margin-top: 1rem;
		display: flex;
		flex-direction: column;
	}
}

@media only screen and (max-width: 426px) {
	.contact-container .contact-form .contact-form {
		margin: 2rem 0;
	}
	.contact-container .contact-form .contact-form-body {
		display: flex;
		flex-direction: column;
		width: 100%;
		/* margin: 2rem 0; */
	}

	.contact-container .contact-form .contact-form-body .form-group {
		width: 100%;
		margin-top: 0rem;
	}

	.contact-container .contact-form .contact-form-body .form-group2 {
		width: 100%;
	}
}

/* @media only screen and (max-width: 678px) {
  .contact-container .contact-address {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 900px;
  }

  .contact-container .contact-address .address-left {
    height: 50%;
  }

  .contact-container .contact-address .address-right {
    height: 50%;
  }

  .contact-container .contact-address .address-left .address-section {
    padding: 5px;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
} */
