.mob-filter-container {
	position: relative;
	width: 100%;
	display: none;
	flex-direction: column;
	z-index: 999;
	user-select: none !important;
	margin-top: 8vh;
}

.mob-filter-container .mob-filter-content {
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	transform: translateY(-8vh);
	transition: all 5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.mob-filter-container .mob-filter-content.mob-filter-content-active {
	transform: translateY(100vh);
	transition: transform 5s cubic-bezier(0.075, 0.82, 0.165, 1);
	overflow: hidden;
}

.mob-filter-container .mob-filter-content .mob-filter-body {
	width: 100%;
	height: 100vh;
	background-color: #b69e6e;
	display: flex;
	flex-direction: column;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-header {
	background-color: #231535;
	height: 10vh;
	width: 100%;
	display: flex;
	padding: 0 12.8px;
	justify-content: space-between;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-header .header-text {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-header .header-text p {
	font-size: 18px;
	margin-right: 1rem;
	color: #fff;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-header .header-text .react-filter-icon {
	color: #fff;
	height: 1.3rem;
	width: 1.3rem;
	margin-top: 10px;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-header .cancel {
	/* display: flex;
  align-items: center; */
	position: relative;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-header .cancel .bar {
	position: absolute;
	top: 35px;
	right: 0;
	width: 1.5rem;
	height: 2px;
	background-color: #fff;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-header .cancel .bar:nth-child(1) {
	transform: rotate(45deg);
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-header .cancel .bar:nth-child(2) {
	transform: rotate(-45deg);
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-menu {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 100%;
	height: 10vh;
	background-color: #b69e6e;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-menu .mob-filter-type {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 0.8rem;
	border-bottom: 3px solid #998358;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-menu .mob-filter-type div {
	display: flex;
	align-items: center;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-menu .mob-filter-type div p {
	margin-left: 2rem;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-menu .mob-filter-type p {
	font-size: 0.8rem;
	text-transform: uppercase;
	color: #fff;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-menu .mob-filter-type .dd-icon {
	color: #998358;
	transition: all 0.3s;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-menu .list {
	position: absolute;
	top: 4.5rem;
	width: 100%;
	background: #fff;
	color: #333;
	z-index: 1;
	box-shadow: rgb(153 152 163 / 80%) 0px 0px 10px 1px;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-menu .dd-icon {
	transform: rotate(-90deg);
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-menu .mob-filter-type p {
	font-size: 17px;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-menu .list .sub-menu-item {
	padding: 1rem 0.5rem;
	border-bottom: 1px solid black;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-menu .list .sub-menu-item span {
	color: #deb708;
}

.mob-filter-container .mob-filter-content .mob-filter-body .mob-filter-menu .list .sub-menu-item:last-child {
	border-bottom: none;
}

@media only screen and (max-width: 678px) {
	.mob-filter-container {
		display: block;
	}
}
