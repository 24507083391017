.footer-container {
  width: 100%;
  margin-top: 3rem;
  padding: 3rem;
  background-color: #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-container .footer-wrapper {
  width: 80%;
  height: 100%;
  /* background-color: red; */
  display: flex;
}

.footer-container .footer-wrapper .footer-link {
  width: 250px;
  height: 40px;
  display: flex;
  align-items: center;
}

.footer-container .footer-wrapper .column1 {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer-container .footer-wrapper .column1 .top {
  width: 100%;
  height: 50%;
}

.footer-container .footer-wrapper .column1 .top img {
  cursor: pointer;
  width: 150px;
  /* height: 100px; */
  object-fit: cover;
}

.footer-container .footer-wrapper .column1 .top p {
  font-size: 14px;
  /* margin-left: 1.5rem; */
}

.footer-container .footer-wrapper .column1 .bottom {
  width: 100%;
  height: 50%;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footer-container .footer-wrapper .column1 .bottom p {
  font-weight: bold;
  font-size: 18px;
}

.footer-container .footer-wrapper .column1 .bottom div {
  width: 385px;
  height: 70px;
  display: flex;
  margin-top: 1rem;
}

.footer-container .footer-wrapper .column1 .bottom div input {
  width: 315px;
  height: 70px;
  padding: 0 1rem;
  border: none;
  outline: none;
}

.footer-container .footer-wrapper .column1 .bottom div button {
  width: 70px;
  height: 70px;
  background-color: #fbd784;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
}

.footer-container .footer-wrapper .column2 {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-container .footer-wrapper .column2 h1 {
  font-size: 20px;
  font-weight: bold;
  color: #0c1e27;
  margin-bottom: 2rem;
}

.footer-container .footer-wrapper .column2 a {
  font-size: 16px;
  color: #0c1e27;
  cursor: pointer;
  text-decoration: none;
}

.footer-container .footer-wrapper .column2 div {
  width: 50%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-container .footer-wrapper .column2 div span {
  cursor: pointer;
}

@media only screen and (max-width: 769px) {
  .footer-container {
    width: 100%;
    padding: 1rem;
  }
  .footer-container .footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-container .footer-wrapper .column1 {
    width: 100%;
  }

  .footer-container .footer-wrapper .column1 .top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-container .footer-wrapper .column1 .top img {
    /* background-color: red; */
    width: 3.5rem;
    height: 3.5rem;
    object-fit: cover;
  }
  .footer-container .footer-wrapper .column1 .top p {
    white-space: nowrap;
    font-size: 12px;
    margin-left: 0rem;
  }

  .footer-container .footer-wrapper .column2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    /* background-color: red; */
  }

  .footer-container .footer-wrapper .column2 h1 {
    margin-bottom: 0.5rem;
  }

  .footer-container .footer-wrapper .footer-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-container .footer-wrapper .column2 div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0rem;
    padding: 0.5rem 0;
  }

  .footer-container .footer-wrapper .column2 div span {
    margin: 0 2rem;
  }
}
