.home-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.home-container .banner-container {
	width: 100%;
	height: 85vh;
	margin-bottom: 2rem;
	background-size: cover;
	position: relative;
}

.home-container .banner-container .slider-container {
	width: 100%;
	height: 100%;
	position: relative;
	/* overflow: hidden; */
}

.home-container .banner-container .slider-container .slide {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0;
	transition: opacity 0.35s ease-in-out;
}

.home-container .banner-container .slider-container .slide.active {
	opacity: 1;
}

.home-container .banner-container .slider-container .slide img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.home-container .banner-container .slider-dots {
	position: absolute;
	bottom: 30px;
	left: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: center;
}

.home-container .banner-container .slider-dots .slider-dot {
	width: 10px;
	height: 10px;
	background-color: #c5c5c5;
	border-radius: 50%;
	margin: 0.5rem;
	cursor: pointer;
}

.home-container .banner-container .slider-dots .slider-dot.active {
	background-color: #303030;
	cursor: none;
}

.home-container .banner-container .banner-content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	left: 0;
	top: 0;
	padding: 0 5rem;
}

.home-container .banner-container .banner-content h1 {
	font-family: "apple-garamond";
	font-weight: lighter;
	font-size: 48px;
}

.home-container .banner-container .banner-content p {
	margin-top: 1rem;
	color: #303030;
	display: flex;
	align-items: center;
}

.home-container .banner-container .banner-content .banner-arrow {
	transform: rotate(90deg) translate(5px, -5px);
}

.home-container .banner-container .banner-content .p-arrow {
	width: 15px;
}

.home-container .banner-container .banner-content .p-arrow span {
	color: #303030;
	transform: rotate(-90deg);
}

/* Grid container */

.home-container .advertisement-cards {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.home-container .advertisement-cards .advertisement-card {
	width: 30vw;
	height: 30vw;
	margin: 1rem;
	position: relative;
	background-color: #ececec;
}

.home-container .advertisement-cards .advertisement-card .advertisement-card-bg-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.home-container .advertisement-cards .advertisement-card .advertisement-card-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .advertisement-cards .advertisement-card .advertisement-card-content h1 {
	font-size: 36px;
	color: #fff;
	text-align: start;
	width: 100%;
	font-family: "cagliostro";
	font-weight: lighter;
}

.home-container .advertisement-cards .advertisement-card .advertisement-card-content h5 {
	font-size: 14px;
	color: #fff;
	margin: 0.5rem 0;
	text-align: start;
	width: 100%;
	font-weight: lighter;
	font-family: "poppins";
}

.home-container .advertisement-cards .advertisement-card .advertisement-card-content .advertisement-card-content-divider {
	width: 80%;
	object-fit: contain;
	margin: 1rem 0;
}

.home-container .advertisement-cards .advertisement-card .advertisement-card-content p {
	font-size: 14px;
	font-weight: lighter;
	color: #fff;
	margin: 1rem 0;
	text-align: center;
	width: 100%;
	font-family: "poppins";
	max-width: 380px;
	color: #303030;
	/* background-color: red; */
}

.home-container .advertisement-cards .advertisement-card.top-start .advertisement-card-content {
	align-items: start;
}

.home-container .advertisement-cards .advertisement-card.top-start .advertisement-card-content h1 {
	text-align: start;
}

.home-container .advertisement-cards .advertisement-card.top-start .advertisement-card-content h5 {
	text-align: start;
}

.home-container .advertisement-cards .advertisement-card.top-end .advertisement-card-content {
	align-items: flex-end;
}

.home-container .advertisement-cards .advertisement-card.top-end .advertisement-card-content h1 {
	text-align: end;
}

.home-container .advertisement-cards .advertisement-card.top-end .advertisement-card-content h5 {
	text-align: end;
}

.home-container .advertisement-cards .advertisement-card.top-center .advertisement-card-content {
	align-items: center;
}

.home-container .advertisement-cards .advertisement-card.top-center .advertisement-card-content h1 {
	text-align: center;
}

.home-container .advertisement-cards .advertisement-card.top-center .advertisement-card-content h5 {
	text-align: center;
}

.home-container .advertisement-cards .advertisement-card.full-center .advertisement-card-content {
	align-items: center;
	justify-content: center;
}

.home-container .advertisement-cards .advertisement-card.full-center .advertisement-card-content h1 {
	text-align: center;
	font-family: "apple-garamond";
	color: #303030;
}

.home-container .advertisement-cards .advertisement-card.full-center .advertisement-card-content h5 {
	text-align: center;
	font-family: "poppins";
	color: #303030;
}

.home-container .advertisement-cards .advertisement-card.bottom-start .advertisement-card-content {
	justify-content: flex-end;
}

.home-container .advertisement-cards .advertisement-card.bottom-start .advertisement-card-content h1 {
	text-align: start;
}

.home-container .advertisement-cards .advertisement-card.bottom-start .advertisement-card-content h5 {
	text-align: start;
}

.home-container .advertisement-cards .advertisement-card.bottom-end .advertisement-card-content {
	justify-content: flex-end;
}

.home-container .advertisement-cards .advertisement-card.bottom-end .advertisement-card-content h1 {
	text-align: end;
}

.home-container .advertisement-cards .advertisement-card.bottom-end .advertisement-card-content h5 {
	text-align: end;
}

/* about */

.home-container .about-container {
	width: 100%;
	padding: 3.5rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .about-container .about-head {
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.home-container .about-container .about-head .line {
	width: 35%;
	height: 1px;
	background-color: #303030;
}

.home-container .about-container .about-head p {
	font-family: "apple-garamond";
	font-size: 36px;
}

.home-container .about-container .about-body {
	width: 100%;
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .about-container .about-body p {
	font-size: 14px;
}

.home-container .about-container .about-body img {
	margin-top: 2rem;
}

.home-container .about-container .about-foot {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .about-container .about-foot p {
	font-size: 14px;
}

/* exclusive content */

.home-container .exclusive-container {
	width: 100%;
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .exclusive-container .exclusive-head p {
	font-family: "apple-garamond";
	font-size: 36px;
}

.home-container .exclusive-container .exclusive-body {
	width: 100%;
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .exclusive-container .exclusive-body button {
	background-color: #fff;
	padding: 0.5rem 1rem;
	outline: none;
	border: 1.5px solid #303030;
	cursor: pointer;
	font-family: "apple-garamond";
	letter-spacing: 1px;
}

.home-container .exclusive-container .exclusive-body img {
	margin-top: 2rem;
}

.home-container .exclusive-container .exclusive-foot {
	width: 100%;
	padding: 0 2rem;
	display: flex;
	justify-content: space-between;
}

.home-container .exclusive-container .exclusive-foot .exclusive-grid {
	width: 265px;
	height: 265px;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
}

.home-container .exclusive-container .exclusive-foot .exclusive-grid .grid-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(48, 48, 48, 0.5);
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.5s ease-in-out;
}

.home-container .exclusive-container .exclusive-foot .exclusive-grid .grid-overlay button {
	padding: 0.5rem 1rem;
	background-color: transparent;
	outline: none;
	border: 2px solid #fff;
	color: #fff;
	letter-spacing: 1px;
	cursor: pointer;
}

.home-container .exclusive-container .exclusive-foot .exclusive-grid:hover .grid-overlay {
	opacity: 1;
}

.home-container .exclusive-container .exclusive-foot .exclusive-grid img {
	height: 120px;
	width: 120px;
}

.home-container .exclusive-container .exclusive-foot .exclusive-grid .exclusive-title {
	position: absolute;
	top: 80%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding-top: 1rem;
}

/* Gemstone */

.home-container .gemstone-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .gemstone-container .gemstone-head p {
	font-family: "apple-garamond";
	font-size: 36px;
}

.home-container .gemstone-container .gemstone-body {
	width: 100%;
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .gemstone-container .gemstone-foot {
	width: 80%;
	margin: 1rem 0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.home-container .gemstone-container .gemstone-foot .gemstone-grid {
	width: 300px;
	height: 75px;
	background-color: #303030;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.home-container .gemstone-container .gemstone-foot .gemstone-grid img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 0;
	transform: scale(1);
	transition: all 0.7s ease-out;
}

.home-container .gemstone-container .gemstone-foot .gemstone-grid:hover img {
	opacity: 1;
	transform: scale(1.1);
	transition: all 0.7s ease-out;
}

.home-container .gemstone-container .gemstone-foot .gemstone-grid .gemstone-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	transition: opacity 0.5s ease-in;
}

.home-container .gemstone-container .gemstone-foot .gemstone-grid .gemstone-overlay p {
	color: #fbd784;
	letter-spacing: 5px;
	text-align: center;
}

.home-container .gemstone-container .gemstone-foot .gemstone-grid:hover .gemstone-overlay {
	opacity: 0;
	transition: opacity 0.5s ease-out;
}

.home-container .testimonial-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	user-select: none;
}

.home-container .testimonial-container .testimonial-head p {
	font-family: "apple-garamond";
	font-size: 36px;
}

.home-container .testimonial-container .testimonial-divider {
	width: 100%;
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .testimonial-container .testimonial-selector-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.home-container .testimonial-container .testimonial-selector-container .testimonial-selector-arrow {
	margin: 0 1rem;
	cursor: pointer;
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all 0.35s ease-in-out;
}

.home-container .testimonial-container .testimonial-selector-container .testimonial-selector-arrow:hover {
	background-color: #deb708;
	transition: all 0.35s ease-in-out;
}

.home-container .testimonial-container .testimonial-selector-container .testimonial-selector-arrow p {
	font-size: 1.25rem;
	color: #80251c;
	transform: translate(0px, 4px);
}

.home-container .testimonial-container .testimonial-selector-container .testimonial-selector-arrow:nth-child(3) p {
	transform: translate(2px, 4px);
}

.home-container .testimonial-container .testimonial-selector-container .testimonial-selector-carousel {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
}

.home-container .testimonial-container .testimonial-selector-container .testimonial-selector-carousel .testimonial-selector {
	width: 60px;
	height: 60px;
	background-color: #deb708;
	border-radius: 50%;
	margin: 0 1rem;
	cursor: pointer;
	transition: all 0.35s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
}

.home-container .testimonial-container .testimonial-selector-container .testimonial-selector-carousel .testimonial-selector img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.home-container .testimonial-container .testimonial-selector-container .testimonial-selector-carousel .testimonial-selector.active {
	width: 100px;
	height: 100px;
	padding: 0.25rem;
	background-color: #fff;
	border: 2px solid #deb708;
	cursor: none;
	transition: all 0.35s ease-in-out;
}

.home-container .testimonial-container .testimonial-body {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
	max-width: 1200px;
	/* height: 200px; */
}

.home-container .testimonial-container .testimonial-body h1 {
	text-align: center;
	font-size: 36px;
	color: #80251c;
	width: 100%;
}

.home-container .testimonial-container .testimonial-body p {
	text-align: center;
	margin-top: 1rem;
	width: 100%;
}

@media only screen and (max-width: 1280px) {
	.home-container .gemstone-container .gemstone-foot .gemstone-grid {
		width: 250px;
		height: 70px;
		background-color: #303030;
		margin-bottom: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
}

/* Book appointment */

.home-container .book-container {
	width: 100%;
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .book-container .book-head p {
	font-family: "apple-garamond";
	font-size: 36px;
}

.home-container .book-container .book-body {
	width: 100%;
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .book-container .book-foot {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.home-container .book-container .book-foot button {
	margin-top: 2rem;
	width: 250px;
	height: 50px;
	background-color: #303030;
	outline: none;
	border: none;
	color: #fff;
	font-family: "apple-garamond";
	letter-spacing: 1px;
	cursor: pointer;
}

/* Instagram */

.home-container .insta-container {
	width: 100%;
	margin: 5rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .insta-container .insta-head p {
	font-family: "apple-garamond";
	font-size: 36px;
}

.home-container .insta-container .insta-body {
	width: 100%;
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-container .insta-container .insta-foot {
	width: 100%;
	margin: 1rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.home-container .insta-container .insta-foot .rectangle {
	position: absolute;
	top: -20%;
	left: 0%;
	z-index: 1;
}

.home-container .insta-container .insta-foot .zigzag {
	position: absolute;
	top: -90%;
	left: 0%;
	z-index: 2;
}

.home-container .insta-container .insta-foot .insta-grid {
	width: 220px;
	height: 220px;
	z-index: 3;
}

.home-container .insta-container .insta-foot .insta-grid img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

@media only screen and (max-width: 960px) {
	.home-container .advertisement-cards .advertisement-card {
		width: 30vw;
		height: 30vw;
		margin: 15px;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-bg-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content {
		padding: 1rem;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content h1 {
		font-size: 30px;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content h5 {
		font-size: 15px;
		margin: 0;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content p {
		margin: 0;
		font-size: 13px;
	}

	.home-container .about-container {
		padding: 2rem 0;
	}

	.home-container .about-container .about-head p {
		font-size: 30px;
	}
}

@media only screen and (max-width: 900px) {
	.home-container .advertisement-cards .advertisement-card {
		width: 29vw;
		height: 29vw;
		margin: 15px;
	}
	.home-container .advertisement-cards .advertisement-card .advertisement-card-content h1 {
		font-size: 25px;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content h5 {
		font-size: 12px;
		margin: 0;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content p {
		margin: 0;
		font-size: 10px;
		/* max-width: 0px; */
	}
}

@media only screen and (max-width: 769px) {
	.home-container .banner-container {
		width: 100%;
		height: 45vh;
		margin-bottom: 2rem;
		background-size: cover;
		position: relative;
	}

	.home-container .banner-container .slider-dots {
		bottom: 25px;
	}

	.home-container .banner-container .slider-dots .slider-dot {
		width: 10px;
		height: 10px;
		margin: 0.2rem;
	}

	.home-container .banner-container .banner-content {
		padding: 0 3rem;
	}
	.home-container .banner-container .banner-content h1 {
		font-weight: lighter;
		font-size: 25px;
	}

	.home-container .banner-container .banner-content p {
		margin-top: 10px;
		font-size: 15px;
	}

	.home-container .advertisement-cards .advertisement-card {
		width: 29vw;
		height: 29vw;
		margin: 15px;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-bg-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content {
		padding: 0.8rem;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content h1 {
		font-size: 20px;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content h5 {
		font-size: 10px;
		margin: 0;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content p {
		margin: 0;
		font-size: 9px;
	}

	.home-container .about-container {
		padding: 2rem 0;
	}

	.home-container .about-container .about-head p {
		font-size: 25px;
	}

	.home-container .about-container .about-body {
		margin: 1.5rem 0;
	}

	.home-container .about-container .about-body p {
		font-size: 10px;
	}

	.home-container .about-container .about-foot p {
		font-size: 10px;
	}
}

@media only screen and (max-width: 768px) {
	.home-container .banner-container {
		margin-top: 8vh;
	}
	.home-container .advertisement-cards .advertisement-card {
		width: 32vw;
		height: 32vw;
		margin: 2px;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content {
		padding: 0.5rem;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content h1 {
		font-size: 15px;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content h5 {
		font-size: 9px;
		margin: 0;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content p {
		margin: 0;
		font-size: 8px;
	}

	.home-container .about-container .about-head p {
		font-size: 20px;
		white-space: nowrap;
	}

	.home-container .about-container .about-foot {
		width: 90%;
		text-align: center;
	}

	.home-container .exclusive-container {
		padding: 1rem;
	}

	.home-container .exclusive-container .exclusive-body {
		margin: 1rem 0;
	}

	.home-container .exclusive-container .exclusive-head p {
		font-size: 20px;
	}

	.home-container .exclusive-container .exclusive-foot .exclusive-grid {
		margin-bottom: 1rem;
	}

	.home-container .exclusive-container .exclusive-body button {
		font-size: 10px;
	}

	.home-container .exclusive-container .exclusive-foot .exclusive-grid img {
		height: 100px;
		width: 100px;
	}

	.home-container .exclusive-container .exclusive-foot .exclusive-grid .exclusive-title {
		font-size: 10px;
	}

	.home-container .exclusive-container .exclusive-foot {
		display: flex;
		flex-wrap: wrap;
		padding: 0 0.5rem;
	}

	.home-container .exclusive-container .exclusive-foot .exclusive-grid {
		width: 200px;
		height: 200px;
		margin-bottom: 1rem;
	}

	.home-container .gemstone-container .gemstone-head p {
		font-size: 20px;
	}

	.home-container .gemstone-container .gemstone-body {
		margin: 1rem 0;
	}

	.home-container .gemstone-container .gemstone-foot .gemstone-grid {
		width: 200px;
		height: 50px;
	}

	.home-container .book-container .book-head p {
		font-size: 20px;
	}

	.home-container .book-container .book-foot p {
		font-size: 10px;
		text-align: center;
	}

	.home-container .book-container .book-foot button {
		width: 200px;
		height: 40px;
	}
}

@media only screen and (max-width: 570px) {
	.home-container .gemstone-container .gemstone-foot .gemstone-grid {
		width: 150px;
		height: 50px;
	}
}

@media only screen and (max-width: 465px) {
	.home-container .exclusive-container .exclusive-foot .exclusive-grid {
		width: 170px;
		height: 170px;
		margin-bottom: 1rem;
	}

	.home-container .exclusive-container .exclusive-foot .exclusive-grid .exclusive-title {
		font-size: 8px;
	}

	.home-container .testimonial-container .testimonial-selector-container .testimonial-selector-carousel .testimonial-selector {
		display: none;
	}

	.home-container .testimonial-container .testimonial-selector-container .testimonial-selector-carousel .testimonial-selector.active {
		display: flex;
	}
}

@media only screen and (max-width: 426px) {
	.home-container .banner-container {
		width: 100%;
		height: 25vh;
		margin-bottom: 2rem;
		background-size: cover;
		position: relative;
	}
	.home-container .banner-container .slider-dots {
		bottom: 15px;
	}

	.home-container .banner-container .slider-dots .slider-dot {
		width: 5px;
		height: 5px;
		margin: 0.2rem;
	}

	.home-container .banner-container .banner-content {
		padding: 0 2rem;
	}
	.home-container .banner-container .banner-content h1 {
		font-weight: lighter;
		font-size: 18px;
	}

	.home-container .banner-container .banner-content p {
		margin-top: 7px;
		font-size: 10px;
	}

	.home-container .advertisement-cards .advertisement-card {
		width: 48vw;
		height: 48vw;
		margin: 2px;
	}

	.home-container .advertisement-cards .advertisement-card:nth-child(9) {
		display: none;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content {
		padding: 0.5rem;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content h1 {
		font-size: 15px;

		/* background-color: red; */
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content h5 {
		font-size: 11px;
		margin: 0;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content p {
		margin: 0;
		font-size: 10px;
		/* background-color: red; */
		/* max-width: 0px; */
	}
	.home-container .about-container .about-head p {
		font-size: 18px;
	}
	.home-container .exclusive-container .exclusive-head p {
		font-size: 18px;
	}

	.home-container .gemstone-container .gemstone-foot {
		width: 85%;
	}

	.home-container .gemstone-container .gemstone-foot .gemstone-grid {
		width: 160px;
		height: 45px;
	}

	.home-container .gemstone-container .gemstone-foot .gemstone-grid .gemstone-overlay p {
		letter-spacing: 2px;
		font-size: 14px;
	}

	.home-container .testimonial-container .testimonial-body p {
		font-size: 0.7rem;
	}
}

@media only screen and (max-width: 412px) {
	.home-container .banner-container {
		height: 20vh;
	}
	.home-container .banner-container .slider-dots {
		bottom: 15px;
	}
	.home-container .banner-container .slider-dots .slider-dot {
		width: 5px;
		height: 5px;
		margin: 0.2rem;
	}
	.home-container .banner-container .banner-content {
		padding: 0 2rem;
	}
	.home-container .banner-container .banner-content h1 {
		font-weight: lighter;
		font-size: 16px;
	}
	.home-container .banner-container .banner-content p {
		margin-top: 5px;
		font-size: 10px;
	}

	.home-container .advertisement-cards {
		/* background: red; */
		padding: 0;
	}

	.home-container .advertisement-cards .advertisement-card {
		width: 48vw;
		height: 48vw;
		margin: 2px;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-bg-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content {
		padding: 0.5rem;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content h1 {
		font-size: 15px;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content h5 {
		font-size: 9px;
		margin: 0;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content p {
		margin: 0;
		font-size: 8px;
	}
	.home-container .exclusive-container .exclusive-foot .exclusive-grid {
		width: 140px;
		height: 140px;
		margin-bottom: 1rem;
	}

	.home-container .exclusive-container .exclusive-foot .exclusive-grid .exclusive-title {
		font-size: 8px;
	}

	.home-container .exclusive-container .exclusive-foot {
		padding: 0 2px;
	}

	.home-container .exclusive-container .exclusive-foot .exclusive-grid .exclusive-title {
		font-size: 9px;
		top: 90%;
	}
}

@media only screen and (max-width: 350px) {
	.home-container .gemstone-container .gemstone-foot .gemstone-grid {
		width: 120px;
		height: 40px;
	}

	.home-container .gemstone-container .gemstone-foot .gemstone-grid .gemstone-overlay p {
		letter-spacing: 1px;
		font-size: 12px;
	}

	.home-container .advertisement-cards .advertisement-card .advertisement-card-content .advertisement-card-content-divider {
		margin: 0.5rem 0;
	}
}
