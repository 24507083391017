.all-products-page {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	align-items: center;
	min-height: 45vh;
}

.all-products-page .product-list {
	width: 100%;
	max-width: 1400px;
	padding: 1rem 0;
	display: flex;
	flex-wrap: wrap;
	min-height: 45vh;
	justify-content: center;
}

.all-products-page .lottie-loader {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 30rem;
	height: 30rem;
}

.all-products-page .lottie-loader p {
	color: #b69e6e;
}

.all-products-page .mob-filter {
	display: none;
}

@media only screen and (max-width: 768px) {
	.all-products-page .product-list {
		padding: 3rem 0;
		margin-top: 8vh;
	}
}

@media only screen and (max-width: 678px) {
	.all-products-page .mob-filter {
		background-color: black;
		width: 50%;
		color: #fff;
		padding: 5px 20px;
		border-radius: 10rem;
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 5rem;
	}
}

@media only screen and (max-width: 425px) {
	.all-products-page .product-list {
		padding: 10px 0;
	}
}
