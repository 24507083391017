.loader {
	border: 5px solid #f3f3f3;
	border-radius: 50%;
	border-top: 5px solid #ddb806;
	width: 40px;
	height: 40px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
	margin: auto;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
