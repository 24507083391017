@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "apple-garamond";
  src: url("./assets/fonts/AppleGaramond.ttf");
}

@font-face {
  font-family: "cagliostro";
  src: url("./assets/fonts/Cagliostro-Regular.ttf");
}

@font-face {
  font-family: "cherry-swash";
  src: url("./assets/fonts/CherrySwash-Regular.ttf");
}

@font-face {
  font-family: "glass-antiqua";
  src: url("./assets/fonts/GlassAntiqua-Regular.ttf");
}

@font-face {
  font-family: "caveat";
  src: url("./assets/fonts/Caveat-VariableFont_wght.ttf");
}

/* 

*,body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
  
}

.App {
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

p {
  color: #303030;
} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "poppins";
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: #fff;
}
a {
  text-decoration: none;
}
img {
  pointer-events: none;
}
li {
  list-style: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: #deb708;
  border-radius: 4px;
}

.viewport {
  margin: 0 0 0 0;
  width: 100%;
  position: fixed;
}

.App {
  cursor: none;
}

.App .cursor {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid rgb(221, 182, 8);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 998;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
}

.App .cursor.link-grow {
  transform: scale(1.2) translate(-50%, -50%);
  background-color: rgba(221, 182, 8, 0.15);
  pointer-events: none;
}

.App .dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #000;
  z-index: 999;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.App .dot.hide {
  display: none;
  pointer-events: none;
}

@media only screen and (max-width: 768px) {
  .App .cursor {
    display: none;
  }

  .App .dot {
    display: none;
  }
}
