.navbar-container {
	width: 100%;
	height: 15vh;
	padding: 0 2rem;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.navbar-container .navbar-logo {
	width: 20%;
	height: 100%;
	display: flex;
	align-items: center;
}

.navbar-container .navbar-logo img {
	height: 80px;
	cursor: pointer;
}

.navbar-container .navbar-menus {
	height: 100%;
	padding: 0 5rem;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.navbar-container .navbar-menus .menu-item {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 130px;
	height: 50px;
	cursor: pointer;
}

.navbar-container .navbar-menus .menu-item p {
	font-size: 14px;
	letter-spacing: 4px;
	transition: all 0.5s ease-in-out;
	color: #303030;
}

.navbar-container .navbar-icons {
	height: 100%;
	width: 20%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.navbar-container .navbar-icons a {
	margin-right: 1.75rem;
	cursor: pointer;
	color: #c4c4c4;
	transition: all 0.5s ease-in-out;
	text-decoration: none;
}

.navbar-container .navbar-icons a:hover {
	color: #303030;
}

@media only screen and (max-width: 769px) {
	.navbar-container {
		display: none;
	}
}
