.slider {
	width: 100%;
	overflow: hidden;
}

.slider .swiper-container {
	width: 100%;
}

.slider .swiper-container .swiper-wrapper {
	width: 100%;
	/* margin-bottom: 1rem; */
}

.slider .swiper-container .swiper-wrapper .swiper-slide {
	/* height: 280px; */
	width: 280px;
	margin-right: 1rem;
}

.swiper-button-next {
	color: black !important;
	height: 40px !important;
	width: 40px !important;
	border-radius: 50%;
	background-color: #f6f6f6;
	box-shadow: 0 0 10px 0 gray;
}

.swiper-button-next::after {
	font-size: 24px !important;
	transform: translate(25%, 0);
}

.swiper-button-prev {
	color: black !important;
	height: 40px !important;
	width: 40px !important;
	border-radius: 50%;
	background-color: #f6f6f6;
	box-shadow: 0 0 10px 0 gray;
}

.swiper-button-prev::after {
	font-size: 24px !important;
	transform: translate(-15%, 0);
}

@media (max-width: 768px) {
	.card-title {
		padding: 10px 10px;
	}

	.card-title .card-title-mini p {
		font-size: 16px;
	}

	.card-title a {
		/* width: 10%;
        height: 100%; */
		font-size: 16px !important;
	}

	.card-title .card-title-mini .lines .line-through {
		width: 12px;
		margin: 1px 0;
	}

	.card-title .card-title-mini .lines .horizontal {
		width: 25px;
	}

	.slider .swiper-container .swiper-wrapper .swiper-slide {
		height: auto;
	}

	.swiper-button-next {
		display: none !important;
	}

	.swiper-button-prev {
		display: none !important;
	}
}

@media (max-width: 468px) {
	.card-title a {
		font-size: 6px;
	}
	.slider .swiper-container .swiper-wrapper .swiper-slide {
		width: 180px;
	}
}
