.product-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 45vh;
}

.product-container .product-details {
	width: 90%;
	padding: 2rem 0;
	display: flex;
}

.product-container .product-details .product-left {
	width: 50%;
	display: flex;
}

.product-container .product-details .product-left .image-list {
	width: 20%;
	height: 100%;
	padding: 1rem;
	display: flex;
	flex-direction: column;
}

.product-container .product-details .product-left .image-list .s-img {
	width: 100%;
	height: 100px;
	margin-bottom: 1rem;
	border: 1px solid #999999;
	border-radius: 10px;
	cursor: pointer;
}

.product-container .product-details .product-left .image-list .s-img.active {
	border: 2px solid #dcb903;
}

.product-container .product-details .product-left .image-list .s-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.product-container .product-details .product-left .image-main {
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
}

.product-container .product-details .product-left .image-main img {
	width: 80%;
	object-fit: contain;
}

.product-container .product-details .product-right {
	width: 50%;
	padding: 1rem;
	display: flex;
	flex-direction: column;
}

.product-container .product-details .product-right .product-breadcrumb {
	width: 100%;
	padding: 1rem 0;
	display: flex;
	align-items: center;
	white-space: nowrap;
}

.product-container .product-details .product-right .product-breadcrumb p {
	font-size: 18px;
	color: #0c1e27;
}

.product-container .product-details .product-right .product-head {
	width: 100%;
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	white-space: nowrap;
}

.product-container .product-details .product-right .product-head h1 {
	font-family: "apple-garamond";
	color: #0c1e27;
	font-weight: lighter;
	margin-bottom: 1rem;
}

.product-container .product-details .product-right .product-head p {
	color: #0c1e27;
	font-size: 14px;
	margin-bottom: 1rem;
}

.product-container .product-details .product-right .product-head span {
	font-size: 20px;
	color: #0c1e27;
	margin-bottom: 1rem;
	cursor: pointer;
}

.product-container .product-details .product-right .product-head button {
	width: 100%;
	height: 50px;
	align-self: center;
	background-color: #303030;
	outline: none;
	border: none;
	color: #fff;
	font-family: "apple-garamond";
	letter-spacing: 1px;
	cursor: pointer;
}

.product-container .product-details .product-right .product-desc {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.product-container .product-details .product-right .product-desc h1 {
	color: #0c1e27;
	font-size: 18px;
	margin-bottom: 1rem;
	letter-spacing: 5px;
	font-weight: bold;
}

.product-container .product-details .product-right .product-desc p {
	font-size: 14px;
	color: #0c1e27;
	line-height: 40px;
	letter-spacing: 0.5px;
}

/* exclusive content */

.product-container .exclusive-container {
	width: 100%;
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.product-container .exclusive-container .exclusive-head p {
	font-family: "apple-garamond";
	font-size: 36px;
}

.product-container .exclusive-container .exclusive-body {
	width: 100%;
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.product-container .exclusive-container .exclusive-body button {
	background-color: #fff;
	padding: 0.5rem 1rem;
	outline: none;
	border: 1.5px solid #303030;
	cursor: pointer;
	font-family: "apple-garamond";
	letter-spacing: 1px;
}

.product-container .exclusive-container .exclusive-body img {
	margin-top: 2rem;
}

.product-container .exclusive-container .exclusive-foot {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2.5rem;
}

.product-container .exclusive-container .exclusive-foot .exclusive-grid {
	width: 280px;
	height: 360px;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
	background-color: #f2f2f2;
}

.product-container .exclusive-container .exclusive-foot .exclusive-grid .grid-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(48, 48, 48, 0.5);
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.5s ease-in-out;
}

.product-container .exclusive-container .exclusive-foot .exclusive-grid .grid-overlay button {
	padding: 0.5rem 1rem;
	background-color: transparent;
	outline: none;
	border: 2px solid #fff;
	color: #fff;
	letter-spacing: 1px;
	cursor: pointer;
}

.product-container .exclusive-container .exclusive-foot .exclusive-grid:hover .grid-overlay {
	opacity: 1;
}

.product-container .exclusive-container .exclusive-foot .exclusive-grid img {
	height: 200px;
	width: 200px;
}

/* Feature section */

.product-container .feature-container {
	width: 100%;
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.product-container .feature-container .feature-foot {
	width: 70%;
	display: flex;
	justify-content: space-between;
}

.product-container .feature-container .feature-foot .feature-grid {
	width: 320px;
	padding: 1rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-container .feature-container .feature-foot .feature-grid img {
	height: 35px;
	width: 35px;
	margin-right: 1rem;
}

.product-container .feature-container .feature-foot .feature-grid p {
	font-size: 14px;
	color: #303030;
	letter-spacing: 5px;
	font-weight: bold;
}

.product-container .feature-container .feature-foot .feature-grid span {
	font-size: 14px;
	color: #303030;
	margin-bottom: 4rem;
	text-align: center;
}

.product-container .feature-container .feature-foot .feature-grid button {
	width: 100px;
	cursor: pointer;
	background-color: transparent;
	border: none;
	outline: none;
	color: #80251c;
	font-family: "apple-garamond";
	font-size: 18px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

@media only screen and (max-width: 768px) {
	.product-container .product-details {
		display: flex;
		flex-direction: column;
		margin-top: 8vh;
	}
	.product-container .product-details .product-left {
		width: 100%;
		display: flex;
		flex-direction: column-reverse;
	}

	.product-container .product-details .product-right {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.product-container .product-details .product-right .product-breadcrumb {
		white-space: normal;
	}

	.product-container .product-details .product-left .image-list {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.product-container .product-details .product-left .image-list .s-img {
		width: 5rem;
		margin: 1rem;
	}
}

@media only screen and (max-width: 500px) {
	.product-container .feature-container .feature-foot {
		display: flex;
		flex-direction: column;
		margin-left: -3rem;
	}
	.product-container .feature-container .feature-foot .feature-grid {
		padding-left: 0;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}
}
