.filter-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.filter-container .filter-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.filter-container .filter-content .filter-body {
  width: 100%;
  display: flex;
  color: #fff;
}

.filter-container .filter-content .filter-body .filter-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 25%;
  background: #b69e6e;
}

.filter-container .filter-content .filter-body .filter-menu .filter-type {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.8rem;
  border-right: 3px solid #998358;
}

.filter-container .filter-content .filter-body .filter-menu .filter-type div {
  display: flex;
  align-items: center;
}

.filter-container .filter-content .filter-body .filter-menu .filter-type div p {
  margin-left: 2rem;
}

.filter-container .filter-content .filter-body .filter-menu .filter-type p {
  font-size: 0.8rem;
  text-transform: uppercase;
}

.filter-container .filter-content .filter-body .filter-menu .filter-type .dd-icon {
  color: #998358;
  transition: all 0.3s;
}

.filter-container .filter-content .filter-body .filter-menu .filter-by-price::before {
  content: " ";
  position: absolute;
  left: 0;
  width: 7%;
  height: 40%;
  border: 15px solid #b3b3ff;
  opacity: 0.5;
  background: #b3b3ff;
  clip-path: polygon(0 0, 60% 0, 100% 50%, 60% 100%, 0 100%);
}

.filter-container .filter-content .filter-body .filter-menu .list {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 15rem;
  background: #fff;
  color: #333;
  overflow-y: scroll;
  z-index: 1;
  visibility: hidden;
  box-shadow: rgb(153 152 163 / 80%) 0px 0px 10px 1px;
}

.filter-container .filter-content .filter-body .filter-menu:hover .list {
  visibility: visible;
}

.filter-container .filter-content .filter-body .filter-menu:hover .dd-icon {
  transform: rotate(180deg);
}

.filter-container .filter-content .filter-body .filter-menu:hover .filter-type {
  background: #b3b3ff3b;
  opacity: 0.9;
}

.filter-container .filter-content .filter-body .filter-menu .list .sub-menu-item {
  padding: 1rem 0.5rem;
  border-bottom: 1px solid black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-container .filter-content .filter-body .filter-menu .list .sub-menu-item span {
  color: #deb708;
}

.filter-container .filter-content .filter-body .filter-menu .list .sub-menu-item:last-child {
  border-bottom: none;
}

@media only screen and (max-width: 768px) {
  .filter-container .filter-content .filter-body .filter-menu .filter-type p {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 560px) {
  .filter-container .filter-content .filter-body .filter-menu .filter-type p {
    font-size: 0.6rem;
  }

  .filter-container .filter-content .filter-body .filter-menu .list p {
    font-size: 10px;
  }

  .filter-container .filter-content .filter-body .filter-menu .dd-icon {
    width: 1rem;
    height: 1rem;
  }
}

@media only screen and (max-width: 425px) {
  .filter-container .filter-content .filter-body .filter-menu .filter-type p {
    font-size: 0.5rem;
  }

  .filter-container .filter-content .filter-body .filter-menu .dd-icon {
    width: 0.7rem;
    height: 0.7rem;
  }
}

@media only screen and (max-width: 678px) {
  .filter-container {
    display: none;
  }
}
